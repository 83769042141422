import React from "react";
import Layout from "../../components/Layout";
import { ImageConstants } from "../../constants/Imagconstants";
import { workSlider } from "../../constants/swiperConstants";
import { Swiper, SwiperSlide } from "swiper/react";

const HireDevelopers = () => {
  return (
    <Layout
      title={"Hire software developers | Luxembourg | Razrcorp"}
      description={
        "RazrCorp provides dedicated, passionate and high-performing software development teams to build winning products. From designing, frontend, backend development to high level testing, we have you covered. Scale your development team with RazrCorp."
      }
      keywords={
        "Outsource software developers in Luxembourg,Outsource software developers in europe,Outsource backend developers,Outsource frontend developers"
      }
    >
      <main>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="subpageheader">
                  <h1>Build a high-performance offshore team</h1>
                  <p>
                    We build teams and deliver a custom outsourced software
                    development experience to meet any skill-set, complexity, or
                    scale. We are quality obsessed and result-driven. We work
                    together as a single unit with a common goal and shared
                    definition of success. And that’s how we managed to cater
                    hundreds of companies. You just need to tell your
                    requirements to us and you’re good to go with our perfect
                    team for ideal results.
                  </p>
                  <a
                    href="https://form.jotform.com/222352612041340"
                    target="_blank"
                    className="btn btn-primary mt-5"
                  >
                    Get Started
                  </a>
                  <div className="hire-bg-1">
                    <img
                      loading="lazy"
                      src={ImageConstants.designBg1}
                      alt="design"
                    />
                  </div>
                  <div className="hire-bg-2">
                    <img
                      loading="lazy"
                      src={ImageConstants.portfolioBg}
                      alt="design"
                    />
                  </div>
                  <div className="hire-bg-3">
                    <img
                      loading="lazy"
                      src={ImageConstants.designBg5}
                      alt="design"
                    />
                  </div>
                  <div className="hire-bg-4">
                    <img
                      loading="lazy"
                      src={ImageConstants.hireBg}
                      alt="design"
                    />
                  </div>
                  <div className="hire-bg-5">
                    <img
                      loading="lazy"
                      src={ImageConstants.hireBg2}
                      alt="design"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h2 className="commantitel">How it Works</h2>
              </div>
            </div>
          </div>
          <Swiper {...workSlider} className="workslider">
            <div className="swiper-wrapper">
              <SwiperSlide>
                <div className="work-main  work-main-yellow  border-radius-left-top outsourseslider">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="120"
                      height="120"
                      viewBox="0 0 120 120"
                    >
                      <g
                        id="Group_15093"
                        data-name="Group 15093"
                        transform="translate(-309 -1000)"
                      >
                        <rect
                          id="Rectangle_109"
                          data-name="Rectangle 109"
                          width="120"
                          height="120"
                          transform="translate(309 1000)"
                          fill="rgba(0,0,0,0)"
                        />
                        <path
                          id="noun-document-4760936"
                          d="M229.912,148.557c9.43,0,17.077-7.283,17.077-16.267V67.224c0-8.984-7.645-16.266-17.077-16.266H177.232c-9.43,0-17.076,7.282-17.076,16.266V132.29c0,8.984,7.645,16.267,17.076,16.267ZM186.5,94.063a5.7,5.7,0,1,0,0,11.386h34.152a5.7,5.7,0,1,0,0-11.386ZM180.52,119.19A5.84,5.84,0,0,1,186.5,113.5h11.89a5.7,5.7,0,1,1,0,11.386H186.5A5.84,5.84,0,0,1,180.52,119.19ZM186.5,74.628a5.7,5.7,0,1,0,0,11.387h33.937a5.7,5.7,0,1,0,0-11.387Z"
                          transform="translate(165.927 959.86)"
                          fill="none"
                          stroke="#2e3652"
                          strokeWidth="2.5"
                          fillRule="evenodd"
                        />
                      </g>
                    </svg>

                    <h3>
                      Send Us Your <br /> Business <br /> Requirement
                    </h3>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="work-main work-main-pink  outsourseslider">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="120"
                      height="120"
                      viewBox="0 0 120 120"
                    >
                      <g
                        id="Group_15094"
                        data-name="Group 15094"
                        transform="translate(-775 -1000)"
                      >
                        <rect
                          id="Rectangle_110"
                          data-name="Rectangle 110"
                          width="120"
                          height="120"
                          transform="translate(775 1000)"
                          fill="rgba(170,23,23,0)"
                        />
                        <g
                          id="Group_127"
                          data-name="Group 127"
                          transform="translate(793.075 992.437)"
                        >
                          <path
                            id="Path_505"
                            data-name="Path 505"
                            d="M249.868,118.8h36.953a6.269,6.269,0,0,0,6.267-6.267V71.789a6.269,6.269,0,0,0-6.267-6.267H249.868a6.269,6.269,0,0,0-6.267,6.267v40.74a6.355,6.355,0,0,0,6.267,6.268Zm6.137-17.237a1.293,1.293,0,0,1,1.828-1.828l1.306,1.306,1.306-1.306a1.293,1.293,0,1,1,1.828,1.828l-1.306,1.306,1.306,1.306a1.263,1.263,0,0,1,0,1.828,1.41,1.41,0,0,1-.914.392,1.186,1.186,0,0,1-.914-.392l-1.306-1.306L257.833,106a1.41,1.41,0,0,1-.914.392A1.186,1.186,0,0,1,256,106a1.263,1.263,0,0,1,0-1.828l1.306-1.306Zm0-19.587a1.293,1.293,0,0,1,1.828-1.828l1.306,1.306,1.306-1.306a1.293,1.293,0,0,1,1.828,1.828l-1.306,1.306,1.306,1.306a1.263,1.263,0,0,1,0,1.828,1.41,1.41,0,0,1-.914.392,1.186,1.186,0,0,1-.914-.392l-1.306-1.306-1.306,1.306a1.41,1.41,0,0,1-.914.392,1.186,1.186,0,0,1-.914-.392,1.263,1.263,0,0,1,0-1.828l1.306-1.306Zm18.542,19.587a1.293,1.293,0,0,1,1.828-1.828l1.306,1.306,1.306-1.306a1.293,1.293,0,0,1,1.828,1.828l-1.306,1.306,1.306,1.306a1.263,1.263,0,0,1,0,1.828,1.418,1.418,0,0,1-.914.392,1.186,1.186,0,0,1-.914-.392l-1.306-1.306L276.375,106a1.41,1.41,0,0,1-.915.392,1.186,1.186,0,0,1-.914-.392,1.263,1.263,0,0,1,0-1.828l1.306-1.306Zm0-19.587a1.293,1.293,0,0,1,1.828-1.828l1.306,1.306,1.306-1.306a1.293,1.293,0,1,1,1.828,1.828L279.51,83.28l1.306,1.306a1.263,1.263,0,0,1,0,1.828,1.418,1.418,0,0,1-.914.392,1.186,1.186,0,0,1-.914-.392l-1.306-1.306-1.306,1.306a1.41,1.41,0,0,1-.915.392,1.186,1.186,0,0,1-.914-.392,1.263,1.263,0,0,1,0-1.828l1.306-1.306Z"
                            transform="translate(-227.277 -48.522)"
                            fill="none"
                            stroke="#2e3652"
                            strokeWidth="2"
                          />
                          <path
                            id="Path_506"
                            data-name="Path 506"
                            d="M283.077,437.276v-.522a2.547,2.547,0,0,0-1.828-2.481l-24.94-7.312a2.679,2.679,0,0,0-1.436,0l-24.94,7.312a2.508,2.508,0,0,0-1.828,2.481v.522a3.743,3.743,0,0,0-1.306,2.873,3.918,3.918,0,0,0,7.835,0,3.613,3.613,0,0,0-.392-1.7l18.673-5.614v4.439a3.743,3.743,0,0,0-1.306,2.873,3.918,3.918,0,0,0,7.835,0,3.74,3.74,0,0,0-1.306-2.873v-4.439l18.8,5.484a3.611,3.611,0,0,0-.392,1.7,3.918,3.918,0,1,0,7.835,0,3.677,3.677,0,0,0-1.306-2.743Z"
                            transform="translate(-214.392 -324.284)"
                            fill="none"
                            stroke="#2e3652"
                            strokeWidth="2"
                          />
                          <path
                            id="Path_507"
                            data-name="Path 507"
                            d="M251.289,208.32h-5.224a4.543,4.543,0,0,0-4.571,4.571v2.481a1.234,1.234,0,0,0,1.306,1.306h3.264V233a2.118,2.118,0,0,1-1.567,2.089,5.569,5.569,0,0,0-4.962-3H190.048a5.425,5.425,0,0,0-4.962,3A2.117,2.117,0,0,1,183.519,233V216.807h3.264a1.234,1.234,0,0,0,1.306-1.306V213.02a4.542,4.542,0,0,0-4.571-4.571h-5.354a4.543,4.543,0,0,0-4.571,4.571V215.5a1.234,1.234,0,0,0,1.306,1.306h3.264v16.322a7.356,7.356,0,0,0,6.529,7.312,5.656,5.656,0,0,0,5.354,3.786H208.2v6.529a1.234,1.234,0,0,0,1.306,1.306h1.306v3.264a1.234,1.234,0,0,0,1.306,1.306h5.223a1.234,1.234,0,0,0,1.306-1.306v-3.264h1.306a1.234,1.234,0,0,0,1.306-1.306v-6.529h18.15a5.656,5.656,0,0,0,5.354-3.786,7.454,7.454,0,0,0,6.529-7.312l0-16.323h3.264a1.234,1.234,0,0,0,1.306-1.306V213.02a4.656,4.656,0,0,0-4.571-4.7Z"
                            transform="translate(-173.595 -158.134)"
                            fill="none"
                            stroke="#2e3652"
                            strokeWidth="2"
                          />
                        </g>
                      </g>
                    </svg>

                    <h3>
                      We find the
                      <br /> right fit for you
                    </h3>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="work-main  work-main-sky  outsourseslider">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="120"
                      height="120"
                      viewBox="0 0 120 120"
                    >
                      <g
                        id="Group_17525"
                        data-name="Group 17525"
                        transform="translate(-1431 -1449)"
                      >
                        <rect
                          id="Rectangle_110"
                          data-name="Rectangle 110"
                          width="120"
                          height="120"
                          transform="translate(1431 1449)"
                          fill="rgba(224,32,32,0.71)"
                          opacity="0"
                        />
                        <g
                          id="Group_17524"
                          data-name="Group 17524"
                          transform="translate(739.674 370.517)"
                        >
                          <path
                            id="Path_96560"
                            data-name="Path 96560"
                            d="M216.975,297.14a1.388,1.388,0,1,0,0,2.775h50.02a1.388,1.388,0,1,0,0-2.775Z"
                            transform="translate(509.108 844.943)"
                            fill="#2e3652"
                          />
                          <path
                            id="Path_96561"
                            data-name="Path 96561"
                            d="M122.684,172.233l4.856.006A7.09,7.09,0,0,1,131,174.27l8.867,8.72a1.378,1.378,0,0,0,.968.4h11.249a2.9,2.9,0,0,1,2.074.734,2.678,2.678,0,0,1,.668,1.864,2.491,2.491,0,0,1-.668,1.859,2.687,2.687,0,0,1-2.074.732H139.2a2.593,2.593,0,0,1-1.82-.752l-3.863-3.8a1.388,1.388,0,0,0-2.355.986v15.411a1.387,1.387,0,0,0,.879,1.281l7.435,2.933a3.274,3.274,0,0,1,2.037,2.514l3.616,21.639a3.031,3.031,0,0,1-.567,2.6,3.622,3.622,0,0,1-4.519.758,3.044,3.044,0,0,1-1.389-2.265l-3.177-19.047a1.385,1.385,0,0,0-.859-1.056l-12.478-4.915a3.276,3.276,0,0,1-2.074-3.049V201.8l.038-26.767a2.614,2.614,0,0,1,2.578-2.8Zm0-2.776a5.479,5.479,0,0,0-5.353,5.574l-.038,26.775a6.073,6.073,0,0,0,3.832,5.634l11.74,4.621,3.049,18.27a5.99,5.99,0,0,0,2.655,4.156,6.375,6.375,0,0,0,8.148-1.369,6.01,6.01,0,0,0,1.146-4.792l-3.622-21.633a6.033,6.033,0,0,0-3.75-4.641l-6.562-2.577V188.324l1.5,1.476a5.366,5.366,0,0,0,3.761,1.546h12.839a5.339,5.339,0,0,0,4.176-1.637,5.709,5.709,0,0,0,0-7.461,5.309,5.309,0,0,0-4.176-1.629H141.406l-8.454-8.332c-1.482-1.458-3.284-2.814-5.4-2.821Z"
                            transform="translate(588.089 947.546)"
                            fill="#2e3652"
                          />
                          <path
                            id="Path_96562"
                            data-name="Path 96562"
                            d="M119.358,58.129a7.788,7.788,0,1,1-5.958.089,7.79,7.79,0,0,1,5.958-.089Zm1.018-2.564a10.553,10.553,0,1,0,5.8,5.621,10.573,10.573,0,0,0-5.8-5.621Z"
                            transform="translate(597.228 1039.668)"
                            fill="#2e3652"
                            fillRule="evenodd"
                          />
                          <path
                            id="Path_96563"
                            data-name="Path 96563"
                            d="M92.9,214.107a1.382,1.382,0,0,0-1.369,1.4v19.719a18.54,18.54,0,0,0,1.07,7.486,9.558,9.558,0,0,0,5.914,5.073l.884.313V263a10.483,10.483,0,0,0-7.645,4.839,1.389,1.389,0,0,0,2.369,1.453,7.952,7.952,0,0,1,13.323,0,1.386,1.386,0,1,0,2.362-1.453A10.458,10.458,0,0,0,102.174,263v-13.9l6.619,2.367a1.388,1.388,0,1,0,.93-2.615l-10.275-3.667c-2.514-.9-3.616-2.019-4.3-3.573a16.808,16.808,0,0,1-.839-6.379V215.509a1.385,1.385,0,0,0-1.408-1.4Z"
                            transform="translate(608.791 911.666)"
                            fill="#2e3652"
                          />
                          <path
                            id="Path_96564"
                            data-name="Path 96564"
                            d="M412.414,172.233l-4.851.006a7.072,7.072,0,0,0-3.463,2.03l-8.873,8.72a1.359,1.359,0,0,1-.962.4H383.011a2.917,2.917,0,0,0-2.076.734,2.678,2.678,0,0,0-.668,1.864,2.522,2.522,0,0,0,.668,1.859,2.69,2.69,0,0,0,2.076.732h12.884a2.609,2.609,0,0,0,1.827-.752l3.857-3.8a1.388,1.388,0,0,1,2.356.986v15.411a1.385,1.385,0,0,1-.872,1.281l-7.436,2.933a3.282,3.282,0,0,0-2.043,2.514l-3.616,21.639a3.079,3.079,0,0,0,.567,2.6,3.526,3.526,0,0,0,2.133,1.185,3.469,3.469,0,0,0,2.387-.427,3.048,3.048,0,0,0,1.393-2.265l3.177-19.047a1.377,1.377,0,0,1,.854-1.056l12.476-4.915a3.266,3.266,0,0,0,2.074-3.049v-.006L415,175.034a2.619,2.619,0,0,0-2.584-2.8Zm-.006-2.776a5.484,5.484,0,0,1,5.36,5.574l.039,26.773a6.072,6.072,0,0,1-3.839,5.634L402.23,212.06l-3.049,18.27a5.975,5.975,0,0,1-2.655,4.156,6.367,6.367,0,0,1-8.141-1.369,5.972,5.972,0,0,1-1.146-4.792l3.614-21.633a6.053,6.053,0,0,1,3.756-4.641l6.557-2.577V188.322l-1.5,1.476a5.386,5.386,0,0,1-3.764,1.546H383.068a5.328,5.328,0,0,1-4.175-1.637,5.688,5.688,0,0,1,0-7.461,5.3,5.3,0,0,1,4.175-1.629h10.625l8.452-8.332c1.49-1.458,3.292-2.814,5.406-2.821Z"
                            transform="translate(378.998 947.546)"
                            fill="#2e3652"
                          />
                          <path
                            id="Path_96565"
                            data-name="Path 96565"
                            d="M494.246,58.131a7.786,7.786,0,1,0,5.954.089,7.759,7.759,0,0,0-5.954-.089Zm-1.024-2.564a10.55,10.55,0,1,1-5.8,5.621,10.58,10.58,0,0,1,5.8-5.621Z"
                            transform="translate(291.362 1039.665)"
                            fill="#2e3652"
                            fillRule="evenodd"
                          />
                          <path
                            id="Path_96566"
                            data-name="Path 96566"
                            d="M528.865,214.107a1.386,1.386,0,0,1,1.375,1.4v19.719a18.524,18.524,0,0,1-1.076,7.486,9.531,9.531,0,0,1-5.914,5.073l-.879.313V263a10.451,10.451,0,0,1,7.638,4.839,1.386,1.386,0,1,1-2.362,1.453,7.954,7.954,0,0,0-13.325,0,1.388,1.388,0,1,1-2.367-1.452A10.47,10.47,0,0,1,519.6,263v-13.9l-6.628,2.367a1.387,1.387,0,1,1-.928-2.615l10.281-3.667c2.508-.9,3.614-2.019,4.288-3.573a16.684,16.684,0,0,0,.841-6.379V215.509a1.392,1.392,0,0,1,1.409-1.4Z"
                            transform="translate(271.624 911.666)"
                            fill="#2e3652"
                          />
                          <path
                            id="Path_96567"
                            data-name="Path 96567"
                            d="M259.135,57.305a10.2,10.2,0,0,1,2.923.344,9.535,9.535,0,0,1-2.018,18.729,9.85,9.85,0,0,1-2.91-.338,9.435,9.435,0,0,1-5.685-4.189,1.119,1.119,0,0,0-1.119-.528l-2.3.338,1.921-2.464a1.113,1.113,0,0,0,.229-.852,9.726,9.726,0,0,1,.229-3.954,9.348,9.348,0,0,1,8.734-7.086Zm-.058-2.481a11.945,11.945,0,0,0-11.07,8.918,12.065,12.065,0,0,0-.254,4.335l-2.076,2.641a2.251,2.251,0,0,0-.357,2.311,2.12,2.12,0,0,0,2.177,1.1l2.635-.383a11.733,11.733,0,0,0,6.374,4.718,12.425,12.425,0,0,0,3.641.42,12.043,12.043,0,0,0-1.07-24.062Z"
                            transform="translate(485.357 1039.67)"
                            fill="#2e3652"
                            fillRule="evenodd"
                          />
                          <path
                            id="Path_96568"
                            data-name="Path 96568"
                            d="M338.624,110.364a1.856,1.856,0,1,0-1.859-1.859A1.857,1.857,0,0,0,338.624,110.364Z"
                            transform="translate(411.731 998.014)"
                            fill="#2e3652"
                            fillRule="evenodd"
                          />
                          <path
                            id="Path_96569"
                            data-name="Path 96569"
                            d="M311.234,110.364a1.856,1.856,0,1,0-1.859-1.859A1.861,1.861,0,0,0,311.234,110.364Z"
                            transform="translate(433.74 998.014)"
                            fill="#2e3652"
                            fillRule="evenodd"
                          />
                          <path
                            id="Path_96570"
                            data-name="Path 96570"
                            d="M283.884,110.364a1.856,1.856,0,1,0-1.859-1.859A1.857,1.857,0,0,0,283.884,110.364Z"
                            transform="translate(455.718 998.014)"
                            fill="#2e3652"
                            fillRule="evenodd"
                          />
                        </g>
                      </g>
                    </svg>

                    <h3>
                      Run interviews <br /> and tests
                    </h3>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="work-main work-main-ppt outsourseslider">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="120"
                      height="120"
                      viewBox="0 0 120 120"
                    >
                      <g
                        id="Group_15095"
                        data-name="Group 15095"
                        transform="translate(-309 -1433)"
                      >
                        <rect
                          id="Rectangle_112"
                          data-name="Rectangle 112"
                          width="120"
                          height="120"
                          transform="translate(309 1433)"
                          fill="rgba(170,23,23,0)"
                        />
                        <path
                          id="noun-calender-4552307"
                          d="M191.955,51.717a10.031,10.031,0,0,0-10.033-10.03h-6.689V35h-6.689v6.687H128.411V35h-6.689v6.687h-6.689A10.031,10.031,0,0,0,105,51.717v53.495a10.031,10.031,0,0,0,10.033,10.03h33.712a26.753,26.753,0,1,0,43.209-30.994Zm-12.408,62.557-10.033-10.03a3.345,3.345,0,0,1-.97-2.374V85.152h6.689V100.5l9.064,9.027Zm5.719-35.542a26.756,26.756,0,0,0-40.133,23.137,27.535,27.535,0,0,0,.87,6.687H115.033a3.344,3.344,0,0,1-3.344-3.343V65.092h73.577Z"
                          transform="translate(217 1411)"
                          fill="none"
                          stroke="#2e3652"
                          strokeWidth="2"
                        />
                      </g>
                    </svg>

                    <h3>
                      Test to Hire in <br /> less than 15 days
                    </h3>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="work-main work-main-red outsourseslider">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="120"
                      height="120"
                      viewBox="0 0 120 120"
                    >
                      <g
                        id="Group_17535"
                        data-name="Group 17535"
                        transform="translate(-14164 -18144)"
                      >
                        <rect
                          id="Rectangle_109"
                          data-name="Rectangle 109"
                          width="120"
                          height="120"
                          transform="translate(14164 18144)"
                          fill="rgba(208,23,23,0.65)"
                          opacity="0"
                        />
                        <g
                          id="Group_17534"
                          data-name="Group 17534"
                          transform="translate(14178.846 18157.422)"
                        >
                          <path
                            id="Path_96653"
                            data-name="Path 96653"
                            d="M174.63,53.787l-8.309-.875A34.4,34.4,0,0,0,160.052,37.9l5.248-6.56a2.192,2.192,0,0,0-.146-2.77L160.489,23.9a2.026,2.026,0,0,0-2.77-.146L151.16,29a38.266,38.266,0,0,0-15.015-6.269l-.875-8.309a2.177,2.177,0,0,0-2.041-1.9h-6.56a2.053,2.053,0,0,0-2.041,1.9l-.875,8.309A34.4,34.4,0,0,0,108.737,29l-6.56-5.248a2.026,2.026,0,0,0-2.77.146l-4.665,4.665a2.192,2.192,0,0,0-.146,2.77l5.248,6.56a38.266,38.266,0,0,0-6.269,15.015l-8.309.875a2.177,2.177,0,0,0-1.9,2.041v6.56a2.053,2.053,0,0,0,1.9,2.041l8.309.875A34.4,34.4,0,0,0,99.844,80.32L94.6,86.88a2.192,2.192,0,0,0,.146,2.77l4.665,4.665a2.026,2.026,0,0,0,2.77.146l6.56-5.248a38.266,38.266,0,0,0,15.015,6.269l.875,8.309a2.177,2.177,0,0,0,2.041,1.9h6.56a2.053,2.053,0,0,0,2.041-1.9l.875-8.309a34.4,34.4,0,0,0,15.015-6.269l6.56,5.248a2.026,2.026,0,0,0,2.77-.146l4.665-4.665a2.192,2.192,0,0,0,.146-2.77l-5.248-6.56A38.266,38.266,0,0,0,166.321,65.3l8.309-.875a2.177,2.177,0,0,0,1.9-2.041v-6.56A2.054,2.054,0,0,0,174.63,53.787ZM129.875,85.859A26.679,26.679,0,1,1,156.554,59.18,26.691,26.691,0,0,1,129.875,85.859Z"
                            transform="translate(-83.371 -12.531)"
                            fill="none"
                            stroke="#000"
                            strokeWidth="2.3"
                          />
                          <path
                            id="Path_96654"
                            data-name="Path 96654"
                            d="M352.51,280C353.239,280.292,352.656,280,352.51,280Z"
                            transform="translate(-305.568 -233.35)"
                            fill="none"
                            stroke="#000"
                            strokeWidth="2.3"
                          />
                          <path
                            id="Path_96655"
                            data-name="Path 96655"
                            d="M308.724,271.64v9.913c0,2.333-6.852,3.5-13.7,3.5-1.6,0-3.207,0-4.665-.146a24.478,24.478,0,0,1-8.163-1.75,2.843,2.843,0,0,0-.146-1.166l-.583-2.915c.146-.292.292-.729.437-1.021a3.236,3.236,0,0,0,.146-1.312,4.278,4.278,0,0,0-.583-2.333v-2.478l11.371,3.936a4.633,4.633,0,0,0,2.041.292,6.471,6.471,0,0,0,1.9-.292Z"
                            transform="translate(-246.913 -226.448)"
                            fill="none"
                            stroke="#000"
                            strokeWidth="2.3"
                          />
                          <path
                            id="Path_96656"
                            data-name="Path 96656"
                            d="M280.062,198.687c-17.057-5.831-16.473-6.269-18.223-5.686l-17.347,6.123a1.448,1.448,0,0,0-1.021,1.458V210.2a2.227,2.227,0,0,0-.583,1.458v.437a2,2,0,0,0,.729,1.166l-.875,4.227a1.891,1.891,0,0,0,1.9,2.333h.729a2.045,2.045,0,0,0,1.9-2.333l-.875-4.227a3.174,3.174,0,0,0,.729-1.166c0-.146.146-.292.146-.437a2.59,2.59,0,0,0-.583-1.458v-7.726l15.307,5.394a2.82,2.82,0,0,0,1.9,0l16.328-5.686a2.043,2.043,0,0,0,1.312-1.75,2.928,2.928,0,0,0-1.459-1.75Zm-16.91,3.5c-1.312,0-2.333-.729-2.333-1.6s1.021-1.6,2.333-1.6,2.333.729,2.333,1.6C265.484,201.311,264.463,202.185,263.152,202.185Z"
                            transform="translate(-214.899 -161.366)"
                            fill="none"
                            stroke="#000"
                            strokeWidth="2.3"
                          />
                        </g>
                      </g>
                    </svg>
                    <h3>
                      Knowledge <br /> Transfer
                    </h3>
                  </div>
                </div>
              </SwiperSlide>
              <SwiperSlide>
                <div className="work-main work-main-sky outsourseslider border-radius-right-bottom">
                  <div>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="120"
                      height="120"
                      viewBox="0 0 120 120"
                    >
                      <g
                        id="Group_17536"
                        data-name="Group 17536"
                        transform="translate(-14311 -18144)"
                      >
                        <rect
                          id="Rectangle_3757"
                          data-name="Rectangle 3757"
                          width="120"
                          height="120"
                          transform="translate(14311 18144)"
                          fill="rgba(208,23,23,0.65)"
                          opacity="0"
                        />
                        <path
                          id="noun-report-3977434"
                          d="M214.5,83.965a1.412,1.412,0,0,0-1,.408L195.279,102.6h0c-.02.019-.038.038-.056.059s-.058.07-.084.106-.032.046-.046.069-.024.037-.034.055l-.028.051,0,.009c-.012.025-.024.05-.034.075a1.443,1.443,0,0,0-.106.378c0,.017,0,.034-.007.051l0,.013c0,.028,0,.055,0,.083l0,.048v57.017a5.5,5.5,0,0,0,5.483,5.481h54.027a5.5,5.5,0,0,0,5.483-5.481V89.441a5.5,5.5,0,0,0-5.483-5.474Zm1.414,2.813h38.465a2.628,2.628,0,0,1,2.667,2.66v71.173a2.626,2.626,0,0,1-2.667,2.658H200.355a2.626,2.626,0,0,1-2.667-2.658V105H214.5a1.409,1.409,0,0,0,1.413-1.4Zm-2.825,1.993v13.412H199.679Zm25.027,12.687a1.844,1.844,0,0,0-1.817,1.817v29.677a1.846,1.846,0,0,0,1.817,1.819h6.175a1.848,1.848,0,0,0,1.819-1.819V103.275a1.846,1.846,0,0,0-1.819-1.817Zm1.006,2.823H243.3v27.675h-4.172Zm-14.842,9.2a1.846,1.846,0,0,0-1.819,1.817v17.659a1.848,1.848,0,0,0,1.819,1.819h6.172a1.848,1.848,0,0,0,1.819-1.819V115.295a1.846,1.846,0,0,0-1.819-1.817Zm.994,2.813h4.175v15.665h-4.175Zm-14.833,5.836a1.84,1.84,0,0,0-1.819,1.81v9.016a1.848,1.848,0,0,0,1.819,1.819h6.175a1.846,1.846,0,0,0,1.817-1.819v-9.016a1.838,1.838,0,0,0-1.817-1.81Zm.994,2.816h4.175v7.013h-4.176ZM210.478,141h0a1.407,1.407,0,1,0,0,2.813h34.516a1.407,1.407,0,0,0,0-2.813Zm0,8.131h0a1.412,1.412,0,1,0,0,2.823h34.516a1.412,1.412,0,0,0,0-2.823Z"
                          transform="translate(14143.633 18078.969)"
                          fillRule="evenodd"
                        />
                      </g>
                    </svg>

                    <h3>
                      Consistent <br /> Reporting
                    </h3>
                  </div>
                </div>
              </SwiperSlide>
            </div>
            <div className="swiper-button-next">
              <img loading="lazy" src={ImageConstants.nextArrow} alt="next" />
            </div>
            <div className="swiper-button-prev">
              <img loading="lazy" src={ImageConstants.preArrow} alt="Prev" />
            </div>
          </Swiper>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="sub-width-60">
                  <h2 className="commantitel">
                    Hire the right IT experts <br />
                    for your business
                  </h2>
                  <p className="commanp mt-3">
                    Don’t just outsource, HIRE! We offer IT experts specialized
                    in different technical stacks. Till date, we have catered to
                    100+ businesses across the globe.
                  </p>
                  <p className="commanp mt-3">
                    Whether you are a budding startup or a well-established
                    Organization, we’ve got you covered. All you need to do is
                    connect with us, share your requirements and we’ll find the
                    right developer for your project. We use agile methodologies
                    to build fast, efficient and error-free digital products
                    that help you convert your visions into reality.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="abt-banner">
                  <figure>
                    <img
                      loading="lazy"
                      src={ImageConstants.hirebanner}
                      alt="hire-banner"
                    />
                  </figure>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="sub-width-60">
                  <h2 className="commantitel">
                    On-site <br /> Presence
                  </h2>
                  <p className="commanp mt-3">
                    With our presence in Luxembourg & the United kingdom, you
                    get project managers on-site and simultaneously build an
                    efficient workforce remotely. A solution-driven team for you
                    that loves challenges and drives comprehensive growth.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <div className="lxbanner">
            <img loading="lazy" src={ImageConstants.lxBanner} alt="lx-banner" />
          </div>
        </section>
        <section>
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="sub-width-60">
                  <h2 className="commantitel">
                    We’re your dedicated teammates, an extension of your
                    in-house team
                  </h2>
                  <h3 className="commansubtitle red-text mt-3">
                    Flexible and affordable IT contracts
                  </h3>
                  <p className="commanp mt-3">
                    We work with you to understand your needs, and we have
                    designed a range of flexible outsourced contracts where we
                    support your business needs both in the office and for those
                    working remotely, as wells as adapting our hours of services
                    to match your working days.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>

        <section>
          <div className="container">
            <div className="letstalksection">
              <div className="row align-items-center">
                <div className="col-lg-9 col-md-8">
                  <div className="talktext">
                    <h2 className="commantitel">
                      Build a top-notch team in the cloud
                    </h2>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4">
                  <div className="text-center">
                    <a
                      href="https://form.jotform.com/222352612041340"
                      target="_blank"
                      className="btn btn-primary"
                    >
                      Get in touch
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </Layout>
  );
};

export default HireDevelopers;
